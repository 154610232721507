<template>
  <div class="p-takeover">
    <Generate v-if="currentType === pageType.GENERATE" />
    <Input v-else-if="currentType === pageType.INPUT" />
    <Index v-else />
  </div>
</template>

<script>
/**
 * クエリパラメータで表示するコンテンツを制御する
 * - `/takeover` 引継
 * - `/takeover?generate` 引継_引継コード発行
 * - `/takeover?input` 引継_引継後
 */

const PAGE_TYPE = {
  INDEX: 'INDEX',
  GENERATE: 'GENERATE',
  INPUT: 'INPUT'
};

import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    Index: () => import('@/components/takeover/Index.vue'),
    Generate: () => import('@/components/takeover/Generate.vue'),
    Input: () => import('@/components/takeover/Input.vue')
  },

  setup: (props, context) => {
    const pageType = computed(() => PAGE_TYPE);

    const currentType = computed(() => {
      // 引継コード発行
      if ('generate' in context.root.$route.query) return PAGE_TYPE.GENERATE;
      // 引継実行
      if ('input' in context.root.$route.query) return PAGE_TYPE.INPUT;

      return PAGE_TYPE.INDEX;
    });

    return {
      pageType,
      currentType
    };
  }
});
</script>
